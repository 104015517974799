import RenameNetwork from '@/layouts/Stack/components/RenameNetwork';
import showErrorModal from '@/mixins/showErrorModal';
import Vue from 'vue';
import modals from '@/mixins/modals';

export default {
  mixins: [showErrorModal, modals],
  computed: {
    id() {
      return this.tariff.id;
    },
  },
  methods: {
    renameNetwork: function (instance, item) {
      const that = this;
      return new Promise(() => {
        const selfName = 'RenameNetwork';
        this.$modals.open({
          name: selfName,
          size: 'medium',
          component: RenameNetwork,
          closeOnBackdrop: false,
          props: {
            instance: instance,
          },
          text: this.$t('sure.text'),
          on: {
            instance: data => {
              instance = data;
              Vue.set(this.modal.footer.confirm.props, 'disabled', false);
            },
            notready: () => {
              // console.log('notready');
              Vue.set(this.modal.footer.confirm.props, 'disabled', true);
            },
          },
          onOpen: inst => (this.modal = inst),
          onClose: () => (this.modal = null),
          onDismiss: () => (this.modal = null),
          footer: {
            confirm: {
              props: { title: this.$t('sure.confirm') },
              on: {
                click: () => {
                  this.renameNameNetwork(instance, item)
                    .then(data => {
                      if (data === instance) {
                        this.$modals.close({ name: selfName });
                        this.showResModal('Сеть успешно переименована.');
                      }
                    })
                    .catch(e => this.showError(e));
                },
              },
            },
            cancel: {
              on: {
                click: () => {
                  that.$modals.close();
                },
              },
            },
          },
        });
      });
    },
    renameNameNetwork(name, item) {
      // console.log(item.action);
      return this.$store.dispatch('moduleStack/updateNetwork', {
        network: {
          name: name,
        },
        id: item.id,
        item: 'name',
      });
    },
  },
};
